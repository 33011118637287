<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="11"
    >
      <markets-editable :data="marketData">
      </markets-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
  </v-row>
</template>

<script>
import { mdiSendOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { DateTime } from 'luxon'
import MarketsEditable from './MarketsEditable.vue'

export default {
  components: {
    MarketsEditable,
  },
  setup() {
    const marketData = ref({
      marketData: {
        id: null,
        createdAt: DateTime.local().toISODate(),
        updatedAt: '',
        marketShortName: '',
        marketDescription: '',
        marketProvider: 'REDFIN',
        marketZone: '',
        marketUrlHistorical: '',
        marketUrlDaily: '',
        marketUrlParameters: '',
        marketUrlParameters02: '',
        marketUrlSolds: '',
        marketUrlSales: '',
        marketLatitude: 0,
        marketLongitude: 0,
        isActive: true,
        userRegistered: '',
        userModified: '',
        valid: true,
      },
    })

    return {
      marketData,

      // Icons
      icons: {
        mdiSendOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
